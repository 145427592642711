import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

export interface MyApiHeader {
	key: string;
	value: string;
}

export interface MyApiFormDataObject {
	key: string;
	object: any;
	shouldStringify?: boolean;
}

export enum AvailableAPIs {
	CORE = "core",
	UTILITY = "utility",
}

interface GeneralApiArguments {
	routes: (string | number)[];
	version?: number;
	embed?: string[];
	headers?: MyApiHeader[];
	isFormData?: boolean;
	formDataObjects?: MyApiFormDataObject[];
	apiName?: AvailableAPIs;
	queryParams?: { [key: string]: boolean | string | number };
}
export interface GeneralListArguments extends GeneralApiArguments {
	params: any;
	exclude?: string[];
	apiName?: AvailableAPIs;
}

export interface GeneralGetArguments extends GeneralApiArguments {}

@Injectable({
	providedIn: "root",
})
export class YumealzApiServiceV2 {
	constructor(private httpClient: HttpClient) {}

	private getEndPint(
		route: (string | number)[],
		version: number,
		embed?: string[],
		apiName?: AvailableAPIs
	) {
		const apiMappings = {
			core: environment.newApiUrl,
			utility: environment.utilityApiUrl,
		};
		let baseUrl = apiMappings[apiName || AvailableAPIs.CORE];
		// Ensure baseUrl ends with a slash
		if (!baseUrl.endsWith("/")) {
			baseUrl += "/";
		}

		if (apiName === AvailableAPIs.UTILITY) {
			// If it's a utility endpoint, just join the route parts
			return `${baseUrl}${route.join("/")}`;
			// return `http://localhost:3000/${route.join("/")}`;
		}

		// Existing logic for non-utility endpoints
		if (!embed) embed = ["dashboard", "admin"];
		let routeString = route.join("/");
		if (routeString) routeString = "/" + routeString;
		let embedString = embed.join("/");
		if (embedString) embedString = "/" + embedString;
		return `${environment.newApiUrl}api/v${version}${embedString}${routeString}`;
	}
	private getHeaders(args: GeneralApiArguments) {
		let headers = new HttpHeaders();
		if (args.headers?.length) {
			args.headers.forEach((header) => {
				headers = headers.append(header.key, header.value);
			});
		}
		return headers;
	}

	private createFormData(formDataObjects: MyApiFormDataObject[]): FormData {
		const formData = new FormData();
		formDataObjects.forEach((o) => {
			if (o.shouldStringify) {
				formData.append(o.key, JSON.stringify(o.object));
			} else {
				formData.append(o.key, o.object);
			}
		});
		return formData;
	}
	listData(args: GeneralListArguments) {
		if (!args.version) args.version = 1;
		const endPoint = this.getEndPint(
			args.routes,
			args.version,
			args.embed,
			args.apiName
		);

		let q = "?";
		if (args.exclude?.length) {
			q += "exclude=";
			q += args.exclude
				.filter((e) => e)
				.map((e) => e + ",")
				.join("")
				.slice(0, -1);
			q += "&";
		}
		for (const [key, value] of Object.entries(args.params)) {
			if (value == -100) q += `${key}=all&`;
			else if (value == -200) q += `${key}=${null}&`;
			q += `${key}=${value}&`;
		}
		if (q.slice(-1) == "&") q = q.slice(0, -1);

		let headers = this.getHeaders(args);

		return this.httpClient.get(endPoint + q, {
			headers: headers,
		});
	}

	getData(args: GeneralGetArguments) {
		if (!args.version) args.version = 1;
		let endPoint = `${this.getEndPint(
			args.routes,
			args.version,
			args.embed,
			args.apiName
		)}`;
		let headers = this.getHeaders(args);

		if (args.queryParams) {
			const queryString = Object.entries(args.queryParams)
				.map(
					([key, value]) =>
						`${encodeURIComponent(key)}=${encodeURIComponent(value)}`
				)
				.join("&");
			endPoint += `?${queryString}`;
		}

		return this.httpClient.get(endPoint, { headers });
	}

	postData(args: GeneralApiArguments, body?: any) {
		if (!args.version) args.version = 1;
		let endPoint = this.getEndPint(
			args.routes,
			args.version,
			args.embed,
			args.apiName
		);
		if (!args.apiName) endPoint += "/";

		// Add query parameters if they exist
		if (args.queryParams) {
			const queryString = Object.entries(args.queryParams)
				.map(
					([key, value]) =>
						`${encodeURIComponent(key)}=${encodeURIComponent(value)}`
				)
				.join("&");
			endPoint += `?${queryString}`;
		}

		let headers = this.getHeaders(args);
		if (args.isFormData && args.formDataObjects) {
			const formData = this.createFormData(args.formDataObjects);
			return this.httpClient.post(endPoint, formData, {
				headers,
			});
		}
		return this.httpClient.post(endPoint, body, {
			headers,
		});
	}
	updateData(args: GeneralApiArguments, body?: any, embed?: string[]) {
		if (!args.version) args.version = 1;
		const endPoint = `${this.getEndPint(
			args.routes,
			args.version,
			args.embed,
			args.apiName
		)}/`;

		let headers = this.getHeaders(args);
		if (args.isFormData && args.formDataObjects) {
			const formData = this.createFormData(args.formDataObjects);
			return this.httpClient.patch(endPoint, formData, {
				headers,
			});
		}
		return this.httpClient.patch(endPoint, body, {
			headers,
		});
	}
	deleteData(args: GeneralApiArguments) {
		if (!args.version) args.version = 1;
		const endPoint = `${this.getEndPint(
			args.routes,
			args.version,
			args.embed,
			args.apiName
		)}`;
		let headers = this.getHeaders(args);
		return this.httpClient.delete(endPoint, {
			headers,
		});
	}
}
