<div class="search-group">
	<div
		class="input-group"
		[class.rtl]="appService.getCurrentLanguage() === 'ar'"
		[class.has-search-limit]="showSearchTextLimit"
	>
		<input
			class="form-control"
			type="text"
			[(ngModel)]="searchText"
			(keyup)="keyUp($event)"
			placeholder="{{ placeHolder | translate }}"
		/>
		<button
			type="button"
			class="clear-button"
			aria-label="clear"
			(click)="clearText()"
			*ngIf="searchText"
		>
			<i class="bx bx-x"></i>
		</button>

		<button
			class="search-button"
			aria-label="Search"
			(click)="fireChangeEvent($event)"
			*ngIf="showSearchIcon"
		>
			<i class="bx bx-search"></i>
		</button>
	</div>
	<div
		class="search-limit"
		[class.has-limit]="showSearchTextLimit"
		[class.has-search-limit]="minSearchTextLength"
	>
		{{ "please-enter-at-least" | translate }} {{ minSearchTextLength }}
		{{ "characters-or-more-to-search" | translate }}
	</div>
</div>
