import { Directive, ElementRef, Input } from "@angular/core";
import { AppService } from "../services/app-service/app-service";
import { firstValueFrom, take } from "rxjs";
import { CurrencyISO } from "../models/classes.model";

@Directive({
	selector: "[appCurrency]",
})
export class AppCurrencyDirective {
	// string type is used to allow passing only appCurrency in other components
	@Input("appCurrency") appId?: number | null | string;

	constructor(private el: ElementRef, private appService: AppService) {}

	ngOnInit() {
		this.setCurrency();
	}
	async setCurrency() {
		if (this.appService.isYumealzUser()) {
			this.el.nativeElement.textContent = this.appService.translate(
				CurrencyISO.SAUDI_ARABIA_SAR
			);
			return;
		}
		if (typeof this.appId === "string") this.appId = undefined;

		if (!this.appId) {
			const appsIds = this.appService.getAppIds();
			const currencies: CurrencyISO[] = await Promise.all(
				appsIds.map((appId) =>
					firstValueFrom(this.appService.getCurrency(appId))
				)
			);

			const firstCurrency = currencies[0];
			// check if all currencies are the same
			const allCurrenciesAreSame = currencies.every(
				(currency) => currency === firstCurrency
			);
			if (allCurrenciesAreSame) {
				this.el.nativeElement.textContent =
					this.appService.translate(firstCurrency);
			} else {
				this.appService.notifyFailure(
					this.appService.translate("you-have-multiple-currencies")
				);
				this.el.nativeElement.textContent = "???";
			}
		} else {
			const currency = await firstValueFrom(
				this.appService.getCurrency(this.appId)
			);
			this.el.nativeElement.textContent = this.appService.translate(currency);
		}
	}
}
