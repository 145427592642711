import { AppService } from "src/app/shared/services/app-service/app-service";
import { Injectable, OnDestroy } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from "@angular/router";
import { map, Observable, Subscription, tap } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class AccessGuardGuard implements CanActivate, OnDestroy {
	constructor(private appService: AppService, private router: Router) {}

	subscriptions: Subscription[] = [];
	readonly urlPermissions = {
		"/bulk-update-permission-groups": "can_bulk_update_organization_profiles",
		"/reports/grouped-sub-orders-financial":
			"can_view_sub_order_financial_report",
		"/reports/sub-orders-financial": "can_view_sub_order_financial_report",
		"/orders/all": "can_list_orders",
		"/orders/**": "can_retrieve_order",
		"/restaurants/all": "can_list_restaurants",
		"/restaurants/**": "can_retrieve_restaurant",
		"/migrate/**": "can_migrate_customers",
		"/migrate": "can_migrate_customers",

		"/restaurants/**/inventory": "can_update_product",
		"/restaurants/**/app-config": "can_retrieve_app_config",
		"/restaurants/new": "can_create_restaurant",
		"/restaurants/users": "can_list_restaurant_users",
		"/restaurants/add-user": "can_create_restaurant_user",
		"/restaurants/edit-user/**": "can_update_restaurant_user",
		"/complaints/all": "can_list_complaints",
		"/complaints/**": "can_retrieve_complaint",
		"/subscriptions": "can_list_subscriptions",
		"/subscriptions/all": "can_list_subscriptions",
		"/subscriptions/**": "can_retrieve_subscription",
		"/subscriptions/create": "can_place_order",
		// "/subscriptions/create": "can_create_subscription",
		"/on-demand-orders/all": "can_list_orders",
		"/on-demand-orders/map": "can_list_orders",
		"/on-demand-orders/**": "can_list_orders",
		"/invoices/all": "can_list_sub_order_invoices",

		"/menus/all": "can_list_menus",
		"/menus/**": "can_retrieve_menu",
		"/menus/plans/all": "can_list_menued_plans",
		"/menus/plans/create": "can_create_menued_plan",
		"/menus/plans/**": "can_retrieve_menued_plan",
		"/menus/create": "can_create_plan",
		"/menus/update": "can_update_plan",
		"/menus/prices": "can_list_plan_versions",
		"/menus/plan-tags": "can_list_plan_tags",
		"/menus/import": "can_create_menu",
		"/menus/import/**": "can_create_menu",

		"/customers/all": "can_list_customers",
		"/customers/reports": "can_list_customers",
		"/customers/**": "can_retrieve_customer",

		"/sub-orders/all": "can_list_sub_orders",
		"/sub-orders/**": "can_retrieve_sub_order",
		"/suborders-v2": "can_list_sub_orders",

		"/delivery/sub-orders-map": "can_view_sub_orders_map",
		"/delivery/sub-orders-map/**": "can_view_sub_orders_map",
		"/delivery/on-demand-orders-map": "can_list_orders",
		"/delivery/captains": "can_list_captains",
		"/delivery/captains/**": "can_retrieve_captain",
		"/delivery/add-user": "can_create_captain",
		"/delivery/edit-user/**": "can_update_captain",
		"/delivery/district-groups": "can_create_district_group",
		"/delivery/open-suborders-summary": "can_list_sub_orders",
		"/delivery/zones": "can_list_zones",
		"/delivery/delivery-windows": "can_list_delivery_windows",
		"/reports/store-billOfQty": "can_view_boq_report",
		"/reports/store-packaging": "can_view_on_demand_packaging_report",
		"/reports/sub-billOfQty": "can_view_boq_report",
		"/reports/sub-packaging": "can_view_sub_packaging_report",
		"/reports/delivery-report": "can_list_sub_orders",
		"/reports/summary-finance-report": "can_view_finance_report",
		"/reports/detailed-finance-report": "can_view_detailed_finance_report",
		"/reports/financial": "can_view_sub_order_financial_report",
		"/reports/market-place-financial": "can_retrieve_mp_restaurant_invoice",
		"/reports/sub-delivery": "can_view_sub_delivery_report",
		"/reports/subscriptions-notes": "can_view_sub_packaging_report",
		"/reports/subscriptions": "can_list_subscriptions",
		"/reports/wallets/all": "can_list_wallets",
		"/reports/plans-prices": "can_list_plan_versions",

		"/marketing/abandoned-carts": "can_list_orders",
		"/marketing/vouchers/all": "can_list_vouchers",
		"/marketing/vouchers/**": "can_retrieve_voucher",
		"/marketing/map-tool": "can_list_vouchers",
		"/marketing/corporates/all": "can_list_corporates",
		"/marketing/corporates/corporate-locations/all":
			"can_list_corporate_locations",
		"/marketing/corporates/corporate-locations/**":
			"can_retrieve_corporate_location",
		"/marketing/corporates/**": "can_retrieve_corporate",
		"/dashboard": "can_view_general_statistics",
		"/organizations/all": "can_list_organizations",
		"/organizations/**": "can_retrieve_organization",
		"/organizations/**/profiles/**": "can_retrieve_org_user_profile",
		"/campaigns": "can_list_notifications",
		"/campaigns/**": "can_list_notifications",
		"/campaigns/**/": "can_list_notifications",
		"/campaigns/**/create": "can_create_notification",
		"/campaigns/**/edit": "can_create_notification",
		"/campaigns/**/edit?id=**": "can_create_notification",
		"/products": "can_list_products",
		"/products/**": "can_list_products",
		"/inventory": "can_list_products",
		"/inventory/**": "can_list_products",
		"/my-fatoorah": "can_view_supplier_dashboard",
		"/my-fatoorah/**/deposited-invoices": "can_view_supplier_dashboard",
		"/my-fatoorah/**": "can_view_supplier_dashboard",
		"/sizes": "can_list_menus",
		"/sizes/**": "can_list_menus",
		"/branches": "can_list_restaurant_branches",
		"/branches/**": "can_list_restaurant_branches",
		"/app-settings": "can_retrieve_app_config",
		"/app-settings/**": "can_retrieve_app_config",
		"/app-settings-new": "can_retrieve_app_config",
		"/app-settings-new/**": "can_retrieve_app_config", //TODO MUST BE CHANGED
		"/app-settings-new/**/": "can_retrieve_app_config",
		"/app-settings-new/**/onboarding": "can_retrieve_app_config",
		"/app-settings-new/**/adbanner": "can_retrieve_app_config",
		"/app-settings-new/**/store": "can_retrieve_app_config",
		"/app-settings-new/**/terms-and-conditions": "can_retrieve_app_config",
		"/app-settings-new/**/mobile-app": "can_retrieve_app_config",
		"/app-settings-new/**/subscription": "can_retrieve_app_config",
		"/app-settings-new/**/operation": "can_retrieve_app_config",
		"/app-settings-new/**/reward": "can_retrieve_app_config",
		"/app-settings-new/**/assets": "can_retrieve_app_config",
		"/app-settings-new/**/developer": "can_retrieve_app_config",
		"/app-settings-new/**/customer-services": "can_retrieve_app_config",
		"/app-settings-new/**/domain":  "can_retrieve_app_config",
		"/app-settings-new/**/payment":  "can_retrieve_app_config",
		"/app-settings-new/**/integration":  "can_retrieve_app_config",
		"/app-settings-new/**/brand-identity":  "can_retrieve_app_config",
		"/app-settings-new/**/ratings": "can_retrieve_app_config",
		"/app-settings-new/**/complaints": "can_retrieve_app_config",
		"/analytics": "can_view_quicksight_report",
		"/techrar-apps": "can_install_apps",
		"/techrar-apps/**": "can_install_apps",
		"/otp": "can_list_otps",
		"/whitelist-otps/**": "can_create_whitelist_otp",
		"/whitelist-otps": "can_create_whitelist_otp",
		"/classes/appointments/**": "can_list_subscriptions",
		"/classes/appointments": "can_list_subscriptions",
		"/classes/categories": "can_list_subscriptions",
		"/classes/categories/**": "can_list_subscriptions",
		"/classes/zones": "can_list_subscriptions",
		"/classes/zones/**": "can_list_subscriptions",
		"/classes/trainers": "can_list_subscriptions",
		"/classes/trainers/**": "can_list_subscriptions",
		"/classes/appointments/**/bookings/**": "can_list_subscriptions",
		"/classes/appointments/**/bookings/**?date=**": "can_list_subscriptions",
		"/ratings": "can_read_ratings",
		"/ratings/**": "can_read_ratings",
		"/suborder-statuses": "can_list_operation_statuses",
		"/suborder-statuses/**": "can_list_operation_statuses",
	};

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		const url = this.getProcessedUrl(state.url);

		const permissionRequiredForUrl =
			this.urlPermissions[url as keyof typeof this.urlPermissions];

		return this.appService
			.getDashboardUserProfile()
			.pipe(
				map((user) => {
					if (!user) return false;

					return user.permissions.includes(permissionRequiredForUrl);
				})
			)
			.pipe(
				tap((hasPermission) => {
					if (!hasPermission) {
						let noPermissionMessage = this.appService.translate(
							"you-dont-have-the-required-permission-to-access-this-page-you-need-this-permission"
						);

						noPermissionMessage += `'${permissionRequiredForUrl}'`;
						this.appService.notifyFailure(noPermissionMessage);
						this.navigateToHomePage();
					}
				})
			);
	}

	getProcessedUrl(url: string): string {
		// Split URL into base and query parts
		const [basePath, queryString] = url.split("?");
		const urlParts = basePath.split("/").filter((part) => part !== "");

		// Process path parameters
		for (let i = 0; i < urlParts.length; i++) {
			if (!isNaN(+urlParts[i])) urlParts[i] = "**";
		}

		// Build processed base URL
		let processedUrl = "/" + urlParts.join("/");

		// Handle query parameters if they exist
		if (queryString) {
			const params = new URLSearchParams(queryString);
			const processedParams = new URLSearchParams();

			// Replace all query param values with "**"
			params.forEach((_, key) => processedParams.set(key, "**"));

			processedUrl += "?" + processedParams.toString();
		}

		return processedUrl;
	}

	navigateToHomePage() {
		this.subscriptions.push(
			this.appService.getDashboardUserProfile().subscribe((user) => {
				if (!user) return;

				const needsOnboardingStoredValue =
					localStorage.getItem("needs_onboarding");
				if (needsOnboardingStoredValue !== "false")
					this.router.navigateByUrl("/onboarding");
				if (user.permissions.includes("can_view_general_statistics")) {
					this.router.navigateByUrl("/dashboard");
				} else if (user.permissions.includes("can_list_subscriptions")) {
					this.router.navigateByUrl("/subscriptions");
				} else if (user.permissions.includes("can_view_boq_report")) {
					this.router.navigateByUrl("/reports/sub-billOfQty");
				}
			})
		);
	}
	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}
}
