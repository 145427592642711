import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./shared/guards/auth-guard/auth.guard";
import { NotAuthGuard } from "./shared/guards/not-auth-guard/not-auth.guard";
import { AccessGuardGuard } from "./shared/guards/access-guard/access-guard.guard";

const routes: Routes = [
	// todo change the route names and support Arabic

	// {
	// 	path: "plans",
	// 	loadChildren: () => import("./plan/plan.module").then((m) => m.PlanModule),
	// },
	{
		path: "bulk-update-permission-groups",
		loadChildren: () =>
			import(
				"./organization/components/bulk-update-permissions/bulk-update-permissions.module"
			).then((m) => m.BulkUpdatePermissionsModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "onboarding",
		loadChildren: () =>
			import("./onboarding/onboarding.module").then((m) => m.OnboardingModule),
		canActivate: [AuthGuard],
	},
	{
		path: "menus",
		loadChildren: () => import("./menus/menu.module").then((m) => m.MenuModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "customers",
		loadChildren: () =>
			import("./customers/customers.module").then((m) => m.CustomersModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	{
		path: "dashboard",
		loadChildren: () =>
			import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	{
		path: "organizations",
		loadChildren: () =>
			import("./organization/organizations.module").then(
				(m) => m.OrganizationsModule
			),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	{
		path: "analytics",
		loadChildren: () =>
			import("./quicksight-dashboards/quicksight-dashboards.module").then(
				(m) => m.QuicksightDashboardsModule
			),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "subscriptions",
		loadChildren: () =>
			import("./subscription/subscription.module").then(
				(m) => m.SubscriptionModule
			),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	{
		path: "sub-orders",
		loadChildren: () =>
			import("./sub-order/sub-order.module").then((m) => m.SubOrderModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "suborders-v2",
		loadChildren: () =>
			import(
				"./sub-order/components/suborders-table-v2/suborders-table-v2.module"
			).then((m) => m.SubordersTableV2Module),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "delivery",
		loadChildren: () =>
			import("./delivery/delivery.module").then((m) => m.DeliveryModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	{
		path: "reports",
		loadChildren: () =>
			import("./report/report.module").then((m) => m.ReportModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	// this is temp so that users used to the old link won't have issues
	{
		path: "vouchers",
		redirectTo: "marketing",
	},
	{
		path: "marketing",
		loadChildren: () =>
			import("./marketing/marketing.module").then((m) => m.MarketingModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	{
		path: "restaurants",
		loadChildren: () =>
			import("./restaurants/restaurants.module").then(
				(m) => m.RestaurantsModule
			),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	{
		path: "complaints",
		loadChildren: () =>
			import("./complaint/complaint.module").then((m) => m.ComplaintModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	{
		path: "orders",
		loadChildren: () =>
			import("./order/order.module").then((m) => m.OrderModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	{
		path: "on-demand-orders",
		loadChildren: () =>
			import("./on-demand-order/on-demand-order.module").then(
				(m) => m.OnDemandOrderModule
			),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	// {
	// 	path: "campaigns",
	// 	loadChildren: () =>
	// 		import("./notification-center/notification-center.module").then(
	// 			(m) => m.NotificationCenterModule
	// 		),
	// 	canActivate: [AuthGuard, AccessGuardGuard],
	// },
	{
		path: "campaigns",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/restaurant-navigator/restaurant-navigator.module"
			).then((m) => m.RestaurantNavigatorModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "campaigns/:id",
		loadChildren: () =>
			import("./notification-center/notification-center.module").then(
				(m) => m.NotificationCenterModule
			),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "login",
		loadChildren: () =>
			import("./shared/submodules/otp-login/otp-login.module").then(
				(m) => m.OtpLoginModule
			),
		canActivate: [NotAuthGuard],
	},

	{
		path: "login/v1",
		loadChildren: () =>
			import("./shared/submodules/password-login/password-login.module").then(
				(m) => m.PasswordLoginModule
			),
		canActivate: [NotAuthGuard],
	},
	{
		path: "products/:id",
		loadChildren: () =>
			import(
				"./restaurants/modules/categories-page/categories-page.module"
			).then((m) => m.CategoriesPageModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "products",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/restaurant-navigator/restaurant-navigator.module"
			).then((m) => m.RestaurantNavigatorModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "inventory/:id",
		loadChildren: () =>
			import(
				"./restaurants/modules/restaurant-inventory/restaurant-inventory.module"
			).then((m) => m.RestaurantInventoryModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "inventory",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/restaurant-navigator/restaurant-navigator.module"
			).then((m) => m.RestaurantNavigatorModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "app-settings/:id",

		loadChildren: () =>
			import("./restaurants/modules/app-config/app-config.module").then(
				(m) => m.AppConfigModule
			),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "app-settings-new",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/restaurant-navigator/restaurant-navigator.module"
			).then((m) => m.RestaurantNavigatorModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "app-settings-new/:id",

		loadChildren: () =>
			import(
				"./restaurants/modules/app-configs-new/app-configs-new.module"
			).then((m) => m.AppConfigsNewModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	{
		path: "app-settings",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/restaurant-navigator/restaurant-navigator.module"
			).then((m) => m.RestaurantNavigatorModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "my-fatoorah/:id",
		loadChildren: () =>
			import("./my-fatoorah/my-fatoorah.module").then(
				(m) => m.MyFatoorahModule
			),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "my-fatoorah",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/restaurant-navigator/restaurant-navigator.module"
			).then((m) => m.RestaurantNavigatorModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "sizes/:id",
		loadChildren: () =>
			import(
				"./menus/submodules/restaurant-sizes/restaurant-sizes.module"
			).then((m) => m.RestaurantSizesModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "sizes",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/restaurant-navigator/restaurant-navigator.module"
			).then((m) => m.RestaurantNavigatorModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "branches/:id",
		loadChildren: () =>
			import(
				"./delivery/submodules/restaurant-branches/restaurant-branches.module"
			).then((m) => m.RestaurantBranchesModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "branches",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/restaurant-navigator/restaurant-navigator.module"
			).then((m) => m.RestaurantNavigatorModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "techrar-apps/:id",
		loadChildren: () =>
			import("./techrar-apps/techrar-apps.module").then(
				(m) => m.TechrarAppsModule
			),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "techrar-apps",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/restaurant-navigator/restaurant-navigator.module"
			).then((m) => m.RestaurantNavigatorModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "help-desk",
		loadChildren: () =>
			import("./merchant-request/merchant-request.module").then(
				(m) => m.MerchantRequestModule
			),
		canActivate: [AuthGuard],
	},
	{
		path: "otp",
		loadChildren: () => import("./otp/otp.module").then((m) => m.OtpModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "migrate/:id",
		loadChildren: () =>
			import("./migrate/migrate.module").then((m) => m.MigrateModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "migrate",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/restaurant-navigator/restaurant-navigator.module"
			).then((m) => m.RestaurantNavigatorModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{ path: "", redirectTo: "/login", pathMatch: "full" },
	{
		path: "under-maintenance",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/under-maintenance-page/under-maintenance-page.module"
			).then((m) => m.UnderMaintenancePageModule),
		canActivate: [AuthGuard],
	},
	{
		path: "whitelist-otps/:id",
		loadChildren: () =>
			import("./whitelist-otp/whitelist-otp.module").then(
				(m) => m.WhitelistOtpModule
			),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "whitelist-otps",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/restaurant-navigator/restaurant-navigator.module"
			).then((m) => m.RestaurantNavigatorModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "classes",
		loadChildren: () =>
			import("./classes/classes.module").then((m) => m.ClassesModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "ratings",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/restaurant-navigator/restaurant-navigator.module"
			).then((m) => m.RestaurantNavigatorModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "ratings/:id",
		loadChildren: () =>
			import(
				"./marketing/submodules/ratings-dashboard/ratings-dashboard.module"
			).then((m) => m.RatingsDashboardModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
    path: "sign-up",
		redirectTo: "signup",
	},
	{
		path: "signup",
		loadChildren: () =>
			import("./merchants-onboarding/merchants-onboarding.module").then(
				(m) => m.MerchantsOnboardingModule
			),
		canActivate: [NotAuthGuard],
	},
	{
		path: "home",
		loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
		canActivate: [AuthGuard],
  },
  {
		path: "suborder-statuses",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/restaurant-navigator/restaurant-navigator.module"
			).then((m) => m.RestaurantNavigatorModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "suborder-statuses/:id",
		loadChildren: () =>
			import(
				"./suborder-statuses/suborder-statuses/suborder-statuses.module"
			).then((m) => m.SuborderStatusesModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
    
	{
		path: "**",
		loadChildren: () =>
			import("./shared/submodules/not-found/not-found.module").then(
				(m) => m.NotFoundModule
			),
		canActivate: [AuthGuard],
	},
];
@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
