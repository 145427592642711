import { NgModule } from "@angular/core";
import {
	TranslatePipe,
	TranslateNumericPipe,
} from "./translation/translate-pipe/translate.pipe";

@NgModule({
	declarations: [TranslatePipe, TranslateNumericPipe],
	imports: [],
	exports: [TranslatePipe, TranslateNumericPipe],
})
export class TranslateModule {}
