export const environment = {
	production: false,
	apiUrl: "https://dev-api.yumealz.com/api/v1/",
	newApiUrl: "https://dev-api.yumealz.com/",
	// apiUrl: "http://localhost:8000/api/v1/",
	// newApiUrl: "http://localhost:8000/",
	// utilityApiUrl: "http://localhost:3000/",
	utilityApiUrl: "https://dev-arc.yumealz.com/",
	googleMapApiKey: "AIzaSyAxwz0Uli-CEdak0Eqz3laR1ZBKflXoWt4",
	firebaseConfig: {
		apiKey: "AIzaSyDYGIfh5PHdp20MgOB57MLKZtpTYM2W9gM",
		authDomain: "yumealz.firebaseapp.com",
		projectId: "yumealz",
		storageBucket: "yumealz.appspot.com",
		messagingSenderId: "576390690991",
		appId: "1:576390690991:web:9ae93132266d004370bc5a",
		measurementId: "G-9VJJF8MB6N",
	},
	NotionDB: {
		notionFirebaseApi: "https://forms.yumealz.com",
	},
	Worker: {
		workerApi: "https://nameless-king-0d17.rayan-abotaweel.workers.dev/status",
	},
};
