import { Component, Input, OnInit, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogFormConfig, FormField } from "./dialog-form.model";
import { AppService } from "../../../services/app-service/app-service";
import {
	AvailableAPIs,
	YumealzApiServiceV2,
} from "src/app/shared/services/yumealz-api-service-v2/yumealz-api-v2.service";

@Component({
	selector: "app-dialog-form",
	templateUrl: "./dialog-form.component.html",
	styleUrls: ["./dialog-form.component.scss"],
})
export class DialogFormComponent implements OnInit {
	@Input() config!: DialogFormConfig;
	form!: FormGroup;
	isSubmitting = false;

	constructor(
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<DialogFormComponent>,
		private appService: AppService,
		private apiService: YumealzApiServiceV2,
		@Inject(MAT_DIALOG_DATA) public data: { config: DialogFormConfig }
	) {
		if (data?.config) {
			this.config = data.config;
		}
	}

	ngOnInit(): void {
		this.initForm();
	}

	private initForm(): void {
		const group: any = {};

		this.config.fields.forEach((field) => {
			const validators = this.getValidators(field);
			const control = [field.value || "", validators];
			group[field.name] = control;
		});

		this.form = this.fb.group(group);
	}

	get formFields() {
		return this.config?.fields || [];
	}

	private getValidators(field: FormField) {
		const validatorsList: any[] = [];

		if (field.required) {
			validatorsList.push(Validators.required);
		}

		field.validators?.forEach((validator) => {
			switch (validator.name) {
				case "email":
					validatorsList.push(Validators.email);
					break;
				case "minLength":
					validatorsList.push(Validators.minLength(validator.value));
					break;
				case "maxLength":
					validatorsList.push(Validators.maxLength(validator.value));
					break;
				case "pattern":
					validatorsList.push(Validators.pattern(validator.value));
					break;
			}
		});

		return validatorsList;
	}

	onSubmit() {
		if (this.form.valid && !this.isSubmitting) {
			try {
				const routes = this.config.routes;
				const version = this.config.version || 1;
				const apiName: AvailableAPIs =
					this.config.apiName || AvailableAPIs.CORE;
				const headers = this.config.headers || [];

				this.isSubmitting = true;

				if (this.config.mode === "create") {
					this.apiService
						.postData(
							{ routes, version, apiName, headers: headers },
							this.form.value
						)
						.subscribe({
							next: (res: any) => {
								const successMessage =
									this.config.successMessage || "save-success";
								this.appService.notifySuccess(
									this.appService.translate(successMessage)
								);
								this.dialogRef.close(res);
							},
							error: (error) => {
								console.error("Error saving form:", error);
								this.appService.notifyFailure(
									this.appService.translate("save-error")
								);
								this.isSubmitting = false;
							},
						});
				} else {
					this.apiService
						.updateData(
							{ routes, version, apiName, headers: headers },
							this.form.value
						)
						.subscribe({
							next: (res: any) => {
								const successMessage =
									this.config.successMessage || "save-success";
								this.appService.notifySuccess(
									this.appService.translate(successMessage)
								);
								this.dialogRef.close(res);
							},
							error: (error) => {
								console.error("Error updating form:", error);
								this.appService.notifyFailure(
									this.appService.translate("save-error")
								);
								this.isSubmitting = false;
							},
						});
				}
			} catch (error) {
				console.error("Error in form submission:", error);
				this.appService.notifyFailure(this.appService.translate("save-error"));
				this.isSubmitting = false;
			}
		}
	}

	onCancel() {
		this.dialogRef.close();
	}

	shouldShowField(field: FormField): boolean {
		if (!field.showWhen) return true;
		return this.form.get(field.showWhen.field)?.value === field.showWhen.value;
	}

	getErrorMessage(fieldName: string): string {
		const control = this.form.get(fieldName);
		if (!control || !control.errors) return "";

		const errors = control.errors;
		if (errors["required"]) return "field-required";
		if (errors["email"]) return "invalid-email";
		if (errors["minlength"]) return "min-length-error";
		if (errors["maxlength"]) return "max-length-error";
		if (errors["pattern"]) return "pattern-error";

		return "";
	}

	getMaxLength(field: FormField): number | null {
		return field.validators?.find((v) => v.name === "maxLength")?.value || null;
	}

	getMinLength(field: FormField): number | null {
		return field.validators?.find((v) => v.name === "minLength")?.value || null;
	}

	getPattern(field: FormField): string | null {
		return field.validators?.find((v) => v.name === "pattern")?.value || null;
	}
}
